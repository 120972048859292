<template>
  <div>
    <portal to="page-top-title">Edit Campaign #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'campaigns-single', params: { id: id } }" icon="fa-eye" text>Back to Campaign</btn>
    </portal>

    <box-form action="edit" data-prop="formData" redirect="campaigns-list" :url="`/campaigns/${id}`" :loaded="loaded" loader>
      <template slot="content">
        <campaign-form-general :formData="formData" :data="data" v-cloak/>
        <campaign-form-sending :formData="formData" :data="data" :isBasic="this.isBasic" v-cloak/>
        <campaign-form-recipients :formData="formData" :data="data" :isBasic="this.isBasic" v-cloak/>
        <campaign-form-content :formData="formData" :data="data" :isBasic="this.isBasic" v-cloak/>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import Btn from '@/views/components/simple/Btn'
import CampaignFormGeneral from '@/views/pages/campaigns/partials/forms/General'
import PageData from '@/views/pages/campaigns/partials/forms/PageData'
import Form from '@/modules/utils/Form'
import CampaignTypes from '@/configs/campaign-types'
import CampaignFormContent from '@/views/pages/campaigns/partials/forms/Content'
import CampaignFormSending from '@/views/pages/campaigns/partials/forms/Sending'
import CampaignFormRecipients from '@/views/pages/campaigns/partials/forms/Recipients'

export default {
  name: 'CampaignsEdit',
  metaInfo () {
    return { title: `Campaign #${this.id} | Edit` }
  },
  components: {
    Btn,
    BoxForm,
    CampaignFormGeneral,
    CampaignFormContent,
    CampaignFormSending,
    CampaignFormRecipients
  },
  data () {
    return {
      loaded: false,
      formData: {
        name: Form.types.input(),
        type: Form.types.select(),
        openers_only: Form.types.boolean(false),
        clickers_only: Form.types.boolean(false),
        first_name_default: Form.types.input(),
        starts_now: Form.types.boolean(false),
        starts_at: Form.types.date(),
        labels: Form.types.multiSelect(),
        lists: Form.types.multiSelect(),
        apis: Form.types.multiSelect(),
        sender_categories: Form.types.multiSelect(),
        domains: Form.types.multiSelect(),
        offers: Form.types.multiSelect(),
        templates: Form.types.multiSelect(),
        templates_category: Form.types.select()
      },
      data: {
        domainsDisabled: false,
        labels: [],
        lists: [],
        types: [],
        apis: [],
        sender_categories: [],
        domains: [],
        offers: [],
        templateCats: [],
        templates: []
      }
    }
  },
  computed: {
    id () { return this.$route.params.id },
    campaignType () { return this.formData.type.value },
    templateCat () { return this.formData.templates_category.value },
    openersOnly () { return this.formData.openers_only.value },
    clickersOnly () { return this.formData.clickers_only.value },
    isBasic () { return this.campaignType ? this.campaignType.id === CampaignTypes.BASIC : true }
  },
  created () {
    this.loadEntity()

    PageData.setResult(this.data)
    PageData.loadAll().then()
  },
  watch: {
    templateCat (val) { this.templateCatChanged(val) },
    openersOnly (val) { if (!val) this.formData.clickers_only.value = false },
    clickersOnly (val) { if (val) this.formData.openers_only.value = true }
  },
  methods: {
    loadEntity () {
      this.$http
        .get(`/campaigns/${this.id}`)
        .then((res) => {
          const { data: { data } } = res
          const category = data.templates[0]?.category ?? null

          this.formData.name.value = data.name
          this.formData.type.value = { id: data.type, name: data.type_text }
          this.formData.first_name_default.value = data.settings.first_name_default
          this.formData.openers_only.value = data.settings.openers_only
          this.formData.clickers_only.value = data.settings.clickers_only
          this.formData.starts_at.value = data.starts_at.slice(0, 16)
          this.formData.labels.value = this.mapEntity(data.labels)
          this.formData.lists.value = this.mapEntity(data.lists)
          this.formData.apis.value = this.mapEntity(data.apis)
          this.formData.sender_categories.value = this.mapEntity(data.sender_categories)
          this.formData.domains.value = this.mapEntity(data.domains)
          this.formData.offers.value = this.mapEntity(data.offers)
          this.formData.templates.value = this.mapEntity(data.templates)
          this.formData.templates_category.value = category ? { id: category.id, name: category.name } : null

          this.campaignTypeChanged()

          this.loaded = true
        })
    },
    mapEntity (item) {
      return item.map((el) => { return { id: el.id, name: el.name } })
    },
    templateCatChanged (category) {
      const isBasic = this.formData.type.value.id === CampaignTypes.BASIC
      let currentTemplates = this.formData.templates.value ?? []

      if (Object.isObject(currentTemplates)) {
        currentTemplates = [currentTemplates]
      }

      this.data.templates = []

      PageData.loadTemplates(category.id).then(() => {
        const templatesValue = currentTemplates.length > 0
          ? this.data.templates.filter((template) => currentTemplates.some((ct) => ct.id === template.id))
          : []

        this.formData.templates.value = isBasic ? templatesValue : templatesValue[0]
      })
    },
    campaignTypeChanged () {
      const currentApis = this.formData.apis.value

      this.data.apis = []

      PageData.loadApis(this.formData.type.value.id).then(() => {
        const apisValue = currentApis && currentApis.length > 0
          ? this.data.apis.filter((api) => currentApis.some((ct) => ct.id === api.id))
          : []

        this.formData.apis.value = this.isBasic ? apisValue : apisValue[0]
      })

      const currentOffers = this.formData.offers.value ?? []
      const currentTemplates = this.formData.templates.value ?? []

      this.formData.apis = this.isBasic ? Form.types.multiSelect() : Form.types.select()
      this.formData.templates = this.isBasic ? Form.types.multiSelect() : Form.types.select()
      this.formData.offers = this.isBasic ? Form.types.multiSelect() : Form.types.select()

      this.formData.offers.value = this.$helpers.FormTransform.getChangedSelectVal(currentOffers, !this.isBasic)
      this.formData.templates.value = this.$helpers.FormTransform.getChangedSelectVal(currentTemplates, !this.isBasic)
    }
  }
}
</script>
